<template>
	<section class="user-leaflet">
		<h1 class="user-leaflet__title">Welcome to Longevity InTime AI powered scientific evidence based health check up.</h1>
		<p class="user-leaflet__text">
			We assess the risk of 20 severe diseases with a few blood tests. <br />
			We recommend regular tests (400+ biomarkers) 3-4 times a year to predict disease risks.
		</p>
		<p class="user-leaflet__text">To proceed, follow these steps:</p>
		<p class="user-leaflet__text">1. Choose diseases you want to track.</p>
		<p class="user-leaflet__text">
			2. Email your request to <a href="mailto:teterin@longevityintime.org">teterin@longevityintime.org</a> with:
		</p>
		<p class="user-leaflet__text-list">
			A. Your WhatsApp, mobile number, and email <br />
			B. Name, country, and city <br />
			C. List of diseases to check <br />
			D. Permission to use your blood test results <br />
			E. Duration of your stay
		</p>
		<p class="user-leaflet__text">
			3. We’ll send a price offer for blood collection from a nearby certified lab. First 50 guests get a free AI health
			check-up.
		</p>
		<p class="user-leaflet__text">4. If you accept, we’ll send a prepayment link.</p>
		<p class="user-leaflet__text">
			5. A nurse from the lab will contact you to arrange the blood collection and provide preparation instructions.
		</p>
		<p class="user-leaflet__text">
			6. By emailing us, you allow us to receive your test results. You’ll also get a copy via email.
		</p>
		<p class="user-leaflet__text">
			7. We’ll analyze your results with AI and provide personalized risk assessments and recommendations.
		</p>
		<p class="user-leaflet__text">8. Discuss these AI reports with your doctor(s) to plan further actions.</p>
		<p class="user-leaflet__text">9. If satisfied, we’ll guide your health for life.</p>
		<p class="user-leaflet__text">
			10. You’ll be advised to repeat the blood tests quarterly in your country and send us the results.
		</p>
		<p class="user-leaflet__text">11. We’ll provide AI evaluations and lifespan projections.</p>
		<p class="user-leaflet__text">
			Visit <a href="https://longevityintime.com/" target="_blank">Service by InTime BioTech LLC.</a> Learn about Oleg Teterin, CEO & Founder, who
			co-produced “Chef” and advised a $1.6B family office. Now a leading AI & Computer Vision scientist in longevity.
			Join us in Johannesburg on August 23-24, 2023, for the
			<a href="http://conference.taffds.org" target="_blank">Longevity Summit Africa</a> where Oleg will discuss AI in
			Longevity & Age Reversal.
		</p>
		<router-link to="/shop" class="user-leaflet__button">SHOP</router-link>
	</section>
</template>

<script>
export default {
	name: "UserLeaflet",
	data() {
		return {
			userId: this.$route.params.id,
		};
	},
};
</script>

<style scoped>
.user-leaflet {
	color: #0c1e3c;
	width: 100%;
	background-image: url(../assets/img/svg/back_pattern2.svg);
	background-size: cover;
}

.user-leaflet__title {
	width: 80%;
	margin: 0 auto;
	font-size: 25px;
	line-height: 30px;
	margin: 80px auto 0;
	text-align: center;
}

.user-leaflet__text {
	width: 80%;
	margin: 0 auto;
	font-size: 20px;
	line-height: 30px;
	margin-top: 10px;
	margin-bottom: 0;
}

.user-leaflet__text-list {
	width: 75%;
	margin: 0 auto;
	font-size: 20px;
	line-height: 30px;
}

.user-leaflet__button{
	margin: 30px auto;
	padding: 30px 90px;
	color: #3683fc;
	background-color: inherit;
	border: 1px solid #3683fc;
	border-radius: 60px;
	font-size: 30px;
	display: flex;
	justify-content: center;
	max-width: 270px;
}
</style>